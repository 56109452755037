<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <v-form
      ref="form"
      v-model="valid"
    >
      <base-text-field
        v-model="contactDetail.name"
        :rules="nameRules"
        required
        label="Nom"
      />

      <base-text-field
        v-model="contactDetail.firstname"
        :rules="firstnameRules"
        required
        label="Prénom"
      />

      <base-text-field
        v-model="contactDetail.email"
        :rules="emailRules"
        required
        label="E-mail"
      />

      <base-text-field
        v-model="contactDetail.phone"
        label="Téléphone"
        :rules="phoneRules"
        required
      />

      <base-text-field
        v-model="contactDetail.society"
        label="Société (optionnel)"
      />

      <base-textarea
        v-model="contactDetail.message"
        class="mb-6"
        :rules="messageRules"
        required
        label="Votre message/questions"
      />

      <base-btn
        :color="!theme.isDark ? 'accent' : 'white'"
        outlined
        @click="validateAndSubmit"
      >
        Envoyer
      </base-btn>

      <v-snackbar
        v-model="snackbar"
        :timeout="snackbarData.timeout"
        :color="snackbarData.color"
      >
        {{ snackbarData.message }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { VueReCaptcha } from 'vue-recaptcha-v3'
  import axios from 'axios'
  import qs from 'qs'

  Vue.use(VueReCaptcha, { siteKey: '6LeqZMEZAAAAAE5N1rlwTlaEmI_jGR-LrsAVadQq' })

  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'Laissez nous un message',
      },
    },

    data: () => ({
      valid: true,
      contactDetail: {
        name: '',
        firstname: '',
        email: '',
        message: '',
        phone: '',
        society: '',
      },
      nameRules: [
        v => !!v || 'Le nom est requis',
      ],
      firstnameRules: [
        v => !!v || 'Le prénom est requis',
      ],
      emailRules: [
        v => !!v || 'L\'e-mail est requis',
        v => /.+@.+\..+/.test(v) || 'L\' e-mail n\'est pas valide',
      ],
      phoneRules: [
        v => !!v || 'Le téléphone est requis',
      ],
      messageRules: [
        v => !!v || 'Le message est requis',
      ],
      snackbar: false,
      snackbarData: {
        timeout: 10000,
        message: '',
        color: 'blue',
      },
    }),

    methods: {
      validateAndSubmit () {
        if (this.$refs.form.validate()) {
          this.disableSubmit = true

          this.$recaptcha('contactus').then((token) => {
            this.snackbar = true
            this.snackbarData = {
              timeout: 0,
              message: 'Envoie de l\'email en cours',
              color: 'blue',
            }
            var post = JSON.parse(JSON.stringify(this.contactDetail))
            post.token = token

            var config = {}
            var snackbarData = {
              timeout: 5000,
              message: 'Quelque chose c\'est mal passé',
              color: 'red',
            }
            var that = this
            axios.post('./sendMail.php', qs.stringify(post), config)
              .then(function (response) {
                if (response.status === 200 && typeof response.data !== 'undefined' && typeof response.data.message !== 'undefined') {
                  snackbarData = response.data
                }
              })
              .catch(function (error) {
                // handle error
                console.log(error)
              })
              .then(function () {
                that.snackbar = false
                that.snackbar = true
                that.snackbarData = snackbarData
                if (snackbarData.color === 'green') {
                  that.$refs.form.reset()
                }
              })
          })
        }
      },
      reset () {
        this.$refs.form.reset()
      },
    },
  }
</script>
